<template>
  <ElDialog
    title="Invite Teammate"
    :visible="visible"
    :before-close="onBeforeClose"
  >
    <p>New teammates will receive email notifications about this opportunity.</p>
    <ElForm
      ref="form"
      :model="{ email }"
      :rules="rules"
      @submit.native.prevent
    >
      <ElFormItem
        label="Email address"
        prop="email"
      >
        <ElInput
          v-model="email"
          data-test="Enter email"
          placeholder="Enter email"
        />
      </ElFormItem>
    </ElForm>
    <template #footer>
      <AppButton
        :is-disabled="isDisabled"
        text="Send invite"
        data-test="send invite"
        @click="sendInvite"
      />
    </template>
  </ElDialog>
</template>

<script>
  /**
   * Table component that displays provided users in a table
   *
   */
  export default {
    name: 'InviteTeammateModal',
    props: {
      /**
       * control visibility of the modal
       */
      visible: {
        type: Boolean,
        default: false,
      },
      /**
       * project id for the project we are sending invites
       */
      projectId: {
        type: [String, Number],
        required: true,
      },
      /**
       * domains of the carrier for invite rules
       */
      carrierDomains: {
        type: Array,
        required: true,
      },
      /**
       * users who are already subscribed for invite rules
       */
      existingEmails: {
        type: Array,
        required: true,
      },
    },
    data() {
      return {
        email: '',
        rules: {
          email: [
            {
              validator: (rule, input, callback) => {
                if (!input) return callback(new Error('Email address is required.'));
                const validDomains = this.carrierDomains.filter((domain) => domain !== 'threeflow.com');
                const domain = input.split('@')[1];

                if (!domain || !this.carrierDomains.includes(domain)) {
                  const formattedDomains = validDomains.map((d) => `@${d}`).join(', ');

                  return callback(new Error(`Email must end in ${formattedDomains}`));
                }

                if (this.existingEmails.includes(input)) {
                  return callback(new Error('This user is already subscribed.'));
                }

                callback();

                return true;
              },
              trigger: 'blur',
            },
          ],
        },
      };
    },
    computed: {
      /**
       * Disable the 'invite' button if the email is invalid.
       *
       * @returns {boolean} True if the invite button should be disabled, otherwise false.
       */
      isDisabled() {
        const domain = this.email.split('@')[1];

        if (this.email.trim() === '' || !this.carrierDomains.includes(domain)) {
          return true;
        }

        if (this.existingEmails.includes(this.email)) {
          return true;
        }

        let hasValidationErrors = false;

        if (this.$refs.form) {
          this.$refs.form.validate((valid) => {
            hasValidationErrors = !valid;
          });
        }
        if (hasValidationErrors) {
          return true;
        }

        return false;
      },
    },
    methods: {
      /**
       * Close the dialog if clicking the 'x' button.
       *
       */
      onBeforeClose() {
        this.email = '';
        this.$refs.form.clearValidate();
        this.$emit('toggle-invite-modal');
      },
      /**
       * Send a project invitation and toggle the modal.
       *
       */
      async sendInvite() {
        this.$emit('invite-teammate', this.email);
        this.onBeforeClose();
      },
      /**
       * Reset the form and clear the input.
       */
      resetForm() {
        this.email = '';
        if (this.$refs.form) {
          this.$refs.form.resetFields();
        }
      },
    },
  };
</script>

<style scoped>
:deep(.el-dialog__title) {
  margin-left: -10px;
  font-weight: 800;
  font-size: 18px;
}

:deep(.el-form-item__label) {
  font-weight: 600;
}

:deep(.el-dialog__body) {
  padding-bottom: 0;
}
</style>
