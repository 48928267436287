<template>
  <div class="management-content">
    <p>This functionality has been moved. Please use the Carrier Management page on the Admin site instead.</p>
  </div>
</template>

<script>
  /**
   * View for the listing of Carriers.
   *
   * @exports views/CarrierManagement
   */
  export default {
    name: 'CarrierManagement',
  };
</script>
