<template>
  <div
    v-if="isLoaded"
    class="contact-tables-container"
  >
    <ContactTable
      ref="carrierContactTable"
      title="Carrier team"
      :users="carrierUsers"
      @open-invite-teammate="toggleInviteTeammateModal"
      @unsubscribe-user="unsubscribeUser"
    />

    <ContactTable
      title="Broker team"
      :users="brokerUsers"
    />

    <AppModalNotify
      :visible="isUnsubscribeModalVisible"
      append-to-body
      center-text
      data-test="confirm unsubscribe modal"
      type="danger"
    >
      <template #title>
        Are you sure?
      </template>
      <p>
        {{
          emailToUnsubscribe === userInfo.email
            ? 'You'
            : emailToUnsubscribe
        }} will stop receiving email notifications for this RFP.
      </p>
      <template #confirmCta>
        <AppButton
          data-test="cancel unsubscribe button"
          size="text"
          text="Cancel"
          @click="isUnsubscribeModalVisible = false"
        />
        <AppButton
          data-test="confirm unsubscribe button"
          size="large"
          icon="fa-solid fa-check-circle"
          type="danger"
          text="Confirm"
          @click="confirmUnsubscribe"
        />
      </template>
    </AppModalNotify>

    <InviteTeammateModal
      :visible.sync="showInviteTeammateModal"
      :project-id="projectId"
      :carrier-domains="carrierDomains"
      :existing-emails="existingEmails"
      @invite-teammate="handleInviteTeammate"
      @toggle-invite-modal="toggleInviteTeammateModal"
    />
  </div>
</template>

<script>
  import { mapState, mapActions } from 'pinia';
  import { useAccountStore } from '@/stores/account.js';
  import { useProjectContactsStore } from '@/stores/projectContacts.js';
  import { useProjectStore } from '@/stores/project.js';
  import projectService from '@/services/project.js';
  import ContactTable from '@/components/Contacts/ContactTable.vue';
  import InviteTeammateModal from '@/components/Modals/InviteTeammateModal.vue';

  /**
   * Displays ContactTable components for carrier/broker users under the contacts tab.
   *
   */
  export default {
    name: 'EventsContact',
    components: {
      ContactTable,
      InviteTeammateModal,
    },
    props: {
      /**
       * project that we are displaying users for
       */
      projectId: {
        type: [String, Number],
        required: true,
      },
    },
    data() {
      return {
        showInviteTeammateModal: false,
        carrierId: null,
        carrierDomains: [],
        isUnsubscribeModalVisible: false,
        emailToUnsubscribe: null,
      };
    },
    computed: {
      ...mapState(useAccountStore, ['userInfo']),
      ...mapState(useProjectContactsStore, ['isLoaded', 'carrierUsers', 'brokerUsers', 'existingEmails']),
    },
    /**
     * Get contacts and related information from account  store.
     */
    async created() {
      await this.getContacts();
      this.carrierId = this.userInfo.carrier_id;
      this.carrierDomains = this.userInfo.email_domains;
    },
    methods: {
      ...mapActions(useProjectContactsStore, ['loadContacts', 'isUserSubscribed']),
      ...mapActions(useProjectStore, ['setProjectInfoParameter']),
      /**
       * Send carrier user project invitation.
       *
       * @param {string} email - Email of user to invite, emitted from invite teammate modal.
       */
      async handleInviteTeammate(email) {
        try {
          const response = await projectService.sendProjectInvitation(this.projectId, email, this.carrierId);
          const knownUser = response?.invitation.user_id;
          const alreadyInvited = response?.invitation.email_sent && !knownUser;

          if (knownUser) {
            await this.getContacts();
            await this.updateProjectSubcribeStatus();
          }

          let inviteMessage;

          if (alreadyInvited) {
            inviteMessage = `${email} has already been invited.`;
          } else if (knownUser) {
            inviteMessage = email === this.userInfo.email
              ? "You'll now receive email notifications related to this RFP."
              : `${email} will now receive email notifications related to this RFP.`;
          } else {
            inviteMessage = `${email} has been invited to ThreeFlow and will start receiving opportunity updates once their account setup is complete.`;
          }

          this.$message({
            duration: 5000,
            message: inviteMessage,
            showClose: true,
            type: alreadyInvited ? 'error' : 'success',
          });
        } catch (error) {
          this.$message({
            duration: 5000,
            message: 'There was an error inviting the user',
            showClose: true,
            type: 'error',
          });
        }
      },
      /**
       * Get the contacts and throw error if fails.
       */
      async getContacts() {
        try {
          await this.loadContacts(this.projectId);
        } catch (error) {
          this.$message({
            duration: 3000,
            showClose: true,
            message: 'Could not get contacts',
            type: 'error',
          });
          this.$router.push({ name: 'YourQuotes' });
        }
      },
      /**
       * Toggle the visibility of the invite teammate modal.
       */
      async toggleInviteTeammateModal() {
        this.showInviteTeammateModal = !this.showInviteTeammateModal;
      },
      /**
       * Unsubscribe the user from the event. Skip the warning modal if the user
       * is unsubscribing themselves.
       *
       * @param {string} email - Email of the user to unsubscribe.
       */
      unsubscribeUser(email) {
        this.emailToUnsubscribe = email;
        if (email !== this.userInfo.email) {
          this.isUnsubscribeModalVisible = true;
        } else {
          this.confirmUnsubscribe();
        }
      },
      /**
       * Confirm the unsubscription of the user.
       */
      async confirmUnsubscribe() {
        this.isUnsubscribeModalVisible = false;
        const email = this.emailToUnsubscribe;

        try {
          projectService.patchCarrierProjectSubscription(
            this.projectId,
            'unsubscribe',
            email,
            async () => {
              await this.getContacts();
              await this.updateProjectSubcribeStatus();
              this.$message({
                showClose: true,
                message: `${email === this.userInfo.email ? "You'll" : `${email} will`} no longer receive email notifications related to this RFP.`,
                type: 'success',
              });
            },
          );
        } catch (error) {
          this.$message({
            showClose: true,
            message: 'There was an error unsubscribing the user.',
            type: 'error',
          });
        }
      },
      /**
       * Close the notify modal.
       */
      closeNotifyModal() {
        this.isUnsubscribeModalVisible = false;
        this.emailToUnsubscribe = null;
      },
      /**
       * If a user is subscribing or unsubscribing themselves, ensure that projectInfoParameter is updated
       * so that the subscribe value on RfpStatus (header) is updated accordingly.
       *
       */
      async updateProjectSubcribeStatus() {
        const currentUserEmail = this.userInfo.email;
        const isUserSubscribed = this.carrierUsers.some((user) => user.email === currentUserEmail);

        this.setProjectInfoParameter({ key: 'is_watching', value: isUserSubscribed });
      },
    },
  };
</script>

<style scoped>
  .contact-tables-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
  }
</style>
