<template>
  <section>
    <AppForgotPasswordForm
      v-bind="{
        ...formProps,
        accountService,
        userInfo
      }"
      @setUserEmail="setUserEmail"
      @setTempPasswordStatus="setTempPasswordStatus(true)"
    />
  </section>
</template>

<script>
  import { mapActions, mapState } from 'pinia';
  import { useAccountStore } from '@/stores/account.js';
  import accountService from '@/services/account.js';

  /**
   * Forgot Password
   *
   * @exports Authentication/ForgotPassword
   */
  export default {
    name: 'ForgotPassword',
    data: () => ({ accountService }),
    computed: {
      ...mapState(useAccountStore, ['userInfo']),
      /**
       * Evaluate the route params and return an object with the appropriate props to pass to appForgotPasswordForm.
       *
       * @returns {object}
       */
      formProps() {
        const { projectId } = this.$route.params;
        const name = projectId ? 'ProjectSignIn' : 'SignIn';
        const formProps = {
          linkLogin: { name },
          linkRedirect: { name },
        };

        if (projectId) {
          formProps.linkLogin.params = { projectId };
          formProps.linkRedirect.params = { projectId };
          formProps.projectId = projectId;
        }

        return formProps;
      },
    },

    methods: {
      ...mapActions(useAccountStore, ['setUserEmail', 'setTempPasswordStatus']),
    },
  };
</script>
